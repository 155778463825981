import { Routes, Route } from "react-router-dom";
import Home from "../Home";
import Sidecare from "../Sidecare";
import Announcements from "../Announcements";
import Main from "../Global/Main";
import Team from "../Team/Team";
import Info from "../InfoForm/RequestInfo";
import NewAnnouncements from "../NewAnnouncements";
import Articles from "../Articles";

const Content = () => {
  return (
    <Main>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/penpal" element={<Sidecare />} />
        <Route path="/team" element={<Team />} />
        <Route path="/info" element={<Info />} />
        <Route path="/announcements" element={<NewAnnouncements />} />
        <Route path="/articles" element={<Articles />} />
      </Routes>
    </Main>
  );
};

export default Content;
