import { useEffect, useState } from "react";

const LoadingText = ({ string = "Loading..." }) => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (!message.length) {
      setTimeout(() => {
        setMessage(prev => prev + string[0])
      }, 100)
    } else if (message.length === 1) {
      setTimeout(() => {
        setMessage(prev => prev + string[1])
      }, 100)
    } else if (message.length === 2) {
      setTimeout(() => {
        setMessage(prev => prev + string[2])
      }, 100)
    } else if (message.length === 3) {
      setTimeout(() => {
        setMessage(prev => prev + string[3])
      }, 100)
    } else if (message.length === 4) {
      setTimeout(() => {
        setMessage(prev => prev + string[4])
      }, 100)
    } else if (message.length === 5) {
      setTimeout(() => {
        setMessage(prev => prev + string[5])
      }, 100)
    } else if (message.length === 6) {
      setTimeout(() => {
        setMessage(prev => prev + string[6])
      }, 100)
    } else if (message.length === 7) {
      setTimeout(() => {
        setMessage(prev => prev + string[7])
      }, 100)
    } else if (message.length === 8) {
      setTimeout(() => {
        setMessage(prev => prev + string[8])
      }, 100)
    } else if (message.length === 9) {
      setTimeout(() => {
        setMessage(prev => prev + string[9])
      }, 100)
    } else if (message.length === 10) {
      setTimeout(() => {
        setMessage(prev => prev + string[10])
      }, 100)
    } else if (message.length === 11) {
      setTimeout(() => {
        setMessage(prev => prev + string[11])
      }, 100)
    } else if (message.length === 12) {
      setTimeout(() => {
        setMessage(prev => prev + string[12])
      }, 100)
    } else if (message.length === 13) {
      setTimeout(() => {
        setMessage("")
      }, 2000)
    } else if (message.length === string.length) {
      setTimeout(() => {
        setMessage("")
      }, 2000)
    }
  }, [message]);

  return (
    <div className="h-[100px] w-full flex justify-center items-center">
      <div className="w-[86px]">
      <p className="text-sunburst text-xl h-full w-full">{message}</p>
      </div>
    </div>
  );
};

export default LoadingText