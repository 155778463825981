import penpalText from "../images/homepage/penPalTextCrop.png";
import newPenpalWithLab from "../images/homepage/labBackgroundWithPenPal2.png";
import talkingMouse from "../videos/talkingMouse.mp4";
import penPalByCage from "../images/homepage/penPalByCage.png";
import { useEffect, useRef, useState } from "react";

const Home = () => {
  // useEffect(() => {
  // }, []);

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="lg:w-3/4">
        <img
          src={newPenpalWithLab}
          alt="penpal device and laboratory background"
          className="w-full h-auto z-20"
        />
        <div
          style={{ fontFamily: "MAGISTRAL-BOLD" }}
          className="absolute flex flex-col items-center 2xl:pt-24 lg:pt-10 lg:left-1/4 md:top-[16vh] md:right-0 top-28 right-0 text-shadow px-4 py-1 z-30"
        >
          <p className="text-white font-magBook lg:text-2xl md:text-lg text-sm">
            <i>Introducing a new</i>
          </p>
          <h1 className="lg:text-5xl md:text-4xl lg:py-1 text-xl font-magBold text-shadow text-white">
            TRICORDER INNOVATION
          </h1>
          <p
            style={{ fontFamily: "MAGISTRAL-BOOK" }}
            className="text-white font-magBook lg:text-2xl md:text-lg text-sm"
          >
            <i>The first A.I. powered animal monitoring device</i>
          </p>
        </div>
        <div
          style={{ fontFamily: "CHALETE-LONDONSIXTY" }}
          className="hidden lg:md:flex lg:md:flex-col lg:md:items-center w-full sm:hidden lg:md:"
        >
          <p
            style={{ fontFamily: "HANDELGOTHICITCPRO-MEDIUM" }}
            className="2xl:text-8xl xl:text-7xl text-center pt-4 z-10 lg:text-6xl text-sunburst sm:text-shadow-sm mb-8 md:text-5xl md:pt-8 md:text-sunburst md:text-shadow truncate"
          >
            "If only my mice could talk to me."
          </p>
          <div className="w-full lg:mt-10 flex md:flex-row justify-between items-center md:h-auto md:mb-24">
            <div className="">
              <p className="text-3xl tracking-wide text-justify pt-12 md:text-justify md:pr-14  md:p-0 md:text-2xl lg:mt-10 xl:mt-20">
                Every researcher knows the pain of losing an animal during a
                critical time of discovery. Imagine if that animal could
                communicate when they are stressed, hot or cold, feeling romantic
                or not feeling well. Introducing the very first artificial
                intelligence powered monitor.
              </p>
              <img
                src={penPalByCage}
                alt="penpal beside mouse cage"
                className="lg:pr-24 md:pr-0"
              />
            </div>
            <div className="lg:flex lg:flex-col lg:h-auto md:items-center md:justify-center md:pr-0 md:mr-0 sm:hidden">
              <img
                src={penpalText}
                alt="Penpal chat bubble"
                className="w-9/12 lg:z-20 md:relative md:top-5 md:z-20 lg:static"
              />
              <div className="lg:flex justify-center lg:max-h-auto w-full mb-2">
                <video
                  loop
                  muted={false}
                  autoPlay={false}
                  onLoad={(e) => {
                    e.target.pause();
                    e.target.currentTime = 0;
                  }}
                  onMouseOver={(e) => e.target.play()}
                  onMouseOut={(e) => e.target.pause()}
                  id="talkingMouse"
                  className="lg:w-full lg:max-w-[800px] rounded-3xl md:h-10/12 md:w-4/12"
                >
                  <source src={talkingMouse} type="video/mp4" />
                </video>
              </div>
              <p className="text-2xl text-sunburst font-magBook text-shadow-sm mx-4 text-center">
                (Click anywhere on the page then hover the mouse above!)
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center lg:hidden">
          <img
            src={penpalText}
            alt="Penpal chat bubble"
            className="w-6/12 h-full mt-10"
          />
          <video
            loop
            controls
            className="relative overflow-hidden rounded-2xl w-9/12"
          >
            <source src={talkingMouse} type="video/mp4" />
          </video>
          <p className="text-2xl text-right mr-6 my-4 z-10 text-sunburst text-shadow">
            "If only my mice could talk to me."
          </p>
          <p className="tracking-wide text-justify px-12">
            Every researcher knows the pain of losing an animal during a critical
            time of discovery. Imagine if that animal could communicate when they
            are hungry, sleepy, bored or sick. Introducing the very first
            artificial intelligence powered monitor.
          </p>
          <img src={penPalByCage} alt="penpal beside mouse cage" className="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
