import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {};

const getDevicesURL =
  "https://sql-database-connection-rest-api.azurewebsites.net/api/get-protocols";

export const getDevices = createAsyncThunk("devices/getDevices", async () => {
  try {
    const response = await axios.get(getDevicesURL);
    const data = response.data.records.recordset;
    return data
  } catch (e) {
    return e.message;
  }
});

export const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDevices.fulfilled, (state, action) => {
      return action.payload.reduce(
        (newState, device) => ((newState[device.NaturalID] = device), newState),
        {}
      );
    });
  },
});

export default devicesSlice.reducer;
