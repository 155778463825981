import { configureStore } from '@reduxjs/toolkit'
import userReducer from './slices/user';
import infoFormsReducer from './slices/infoForm';
import devicesReducer from './slices/penPal';

export const store = configureStore({
  reducer: {
    user: userReducer,
    infoForms: infoFormsReducer,
    devices: devicesReducer
  },
});