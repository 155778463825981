import { useEffect, useState } from "react";
import expand from "../images/icons/expand-details.png";

const Announcement = ({ announcement, text }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <div className={`lg:my-4 lg:w-10/12 w-full px-6 flex flex-col items-center rounded-lg ${text === 'white' ? "bg-black/40" : "bg-white/60"} ${showDetails && text === 'white' ? "bg-black/90" : ""} ${showDetails && text === 'black' ? "bg-white/90" : ""} z-[99999] transition-all duration-700 ease-in-out ${text === 'black' ? "text-black" : "text-white"} cursor-pointer`} onClick={toggleDetails}>
      <h3 className={`${text === 'black' ? "text-black" : "text-white"} mt-[20px] text-center font-bold text-2xl md:text-3xl lg:text-5xl tracking-wide`}>
        {announcement.title}
      </h3>
      <p className={`${text === 'black' ? "text-black" : "text-white"} md:text-lg lg:text-xl`}>{announcement.date}</p>
      <div className="flex flex-col items-center">
        <div
          className={`${showDetails
            ? "opacity-1 duration-700 lg:h-72 h-80 m-2 p-2"
            : "opacity-0 duration-500 h-0 invisible m-0 p-0"
            } w-full overflow-auto flex flex-col tracking-wide transition-all ease-in-out`}
        >
          {announcement.linkTitle ? (
            <div className="flex my-4">
              <p className="font-bold tracking-widest">
                Link:&nbsp;
              </p>
              <a
                className="hover:underline text-sunburst"
                href={announcement.link}
                target="_blank"
                rel="noreferrer"
              >
                {announcement.linkTitle}
              </a>
            </div>
          ) : null}
          {announcement.body.length > 1 ? (
            <>
              {announcement.body.map((paragraph, i) => {
                return (
                  <p key={i} className={`${text === 'black' ? "text-black" : "text-white"} my-4 w-full font-chaletSixty`}>{paragraph}</p>
                );
              })}
            </>
          ) : (
            <p className="font-chaletSixty">{announcement.body}</p>
          )}
        </div>
        <div className={`min-w-[124px] w-5/12 h-6 my-4 flex justify-center rounded-xl cursor-pointer bg-gray-900 ${text === 'white' ? "invert" : ""} z-20`}>
          <img
            src={expand}
            alt="chevron"
            className={`bg-slate-200 invert rounded-full w-6 h-full relative z-30`}
          />
        </div>
      </div>
    </div>
  );
};

export default Announcement;
