import { useEffect } from "react";
import Footer from "./components/Global/Footer";
import TopNavbar from "./components/Navbar/TopNavbar";
import Content from "./components/public/Content";
import { store } from "./store/store";
import { Provider } from "react-redux";

const App = () => {

  useEffect(()=>{
    if (window.location.hostname === 'tricorderarraytechnologies.azurewebsites.net') {
      window.location.replace('https://tricorderarray.com')
    }
  }, [])

  return (
    <Provider store={store}>
      <TopNavbar />
      <Content />
      <Footer />
    </Provider>
  );
};

export default App;


/*
<body>
  <p>Robby,</p>
  <p><bold>{body.Name}</bold> is requesting information about Penpal!</p>
  <p>Here's their information:</p>
  <table>
    <tr>
      <td>
        <bold>NAME</bold>
      </td>
      <td>{body.Name}</td>
    </tr>

    <tr>
      <td>
        <bold>INSTITUTION</bold>
      </td>
      <td>{body.Institution}</td>
    </tr>

    <tr>
      <td>
        <bold>ROLE</bold>
      </td>
      <td>{body.Role}</td>
    </tr>

    <tr>
      <td>
        <bold>EMAIL</bold>
      </td>
      <td>{body.Email}</td>
    </tr>

    <tr>
      <td>
        <bold>PHONE</bold>
      </td>
      <td>{body.Phone}</td>
    </tr>

  </table>
  <p>*DO NOT REPLY.</p>
  <p>*This email was generated automatically by Azure Communication Services on behalf of Tricorder Array Technologies, LLC.</p>
</body>

<body>
    <style>
        body {
            font-family: Arial, sans-serif;
        } table {
            width: 100%;
            margin: 20px 0;
            border-collapse: collapse;
        } th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
            margin: 10px 0;
        } #tableContainer {
            width: 100%;
        } .tableItem {
            padding: 5px;
        }
    </style>
    <div id="openingNote">
        <h3>
            * HEALTH CASE *
        </h3>
        <p>
            To
            <b>
                {lastName}, {firstName}
            </b>
            :
        </p>
        <p>
            An animal was found with a possible abnormality. The enclosure is 
            marked with a yellow sticker. The case information is as follows:
        </p>
    </div>
    <div id="tableContainer">
        <table>
            <tr>
                <th>
                    <p class="tableItem">
                        BUILDING
                    </p>
                </th>
                <td>
                    {building}
                </td>
                <th>
                    <p class="tableItem">
                        ROOM
                    </p>
                </th>
                <td>
                    {room}
                </td>
            </tr>
            <tr>
                <th>
                    <p class="tableItem">
                        LAB CONTACT
                    </p>
                </th>
                <td>
                    {contact}
                </td>
                <th>
                    <p class="tableItem">
                        RACK
                    </p>
                </th>
                <td>
                    {rack}
                </td>
            </tr>
            <tr>
                <th>
                    <p class="tableItem">
                        RACK COLUMN
                    </p>
                </th>
                <td>
                    {rackcolumn}
                </td>
                <th>
                    <p class="tableItem">
                        RACK ROW
                    </p>
                </th>
                <td>
                    {rackrow}
                </td>
            </tr>
            <tr>
                <th>
                    <p class="tableItem">
                        BARCODE
                    </p>
                </th>
                <td>
                    {barcode}
                </td>
                <th>
                    <p class="tableItem">
                        SPECIES
                    </p>
                </th>
                <td>
                    {species}
                </td>
            </tr>
            <tr>
                <th>
                    <p class="tableItem">
                        PROTOCOL
                    </p>
                </th>
                <td>
                    {protocol}
                </td>
                <th>
                    <p class="tableItem">
                        BREED
                    </p>
                </th>
                <td>
                    {breed}
                </td>
            </tr>
            <tr>
                <th>
                    <p class="tableItem">
                        OBSERVATION
                    </p>
                </th>
                <td>
                    {observation}
                </td>
                <th>
                    <p class="tableItem">
                        CASE ID
                    </p>
                </th>
                <td>
                    {protocol}
                </td>
            </tr>
            <tr>
                <th>
                    <p class="tableItem">
                        BODY PART
                    </p>
                </th>
                <td>
                    {bodypart}
                </td>
                <th>
                    <p class="tableItem">
                        SESSION NAME
                    </p>
                </th>
                <td>
                    {sessionname}
                </td>
            </tr>
            <tr>
                <th>
                    <p class="tableItem">
                        # AFFECTED
                    </p>
                </th>
                <td>
                    {affected}
                </td>
                <th>
                    <p class="tableItem">
                        # IN CAGE
                    </p>
                </th>
                <td>
                    {incage}
                </td>
            </tr>
            <tr>
                <th>
                    <p class="tableItem">
                        OFFSPRING PRESENT
                    </p>
                </th>
                <td>
                    {offpresent}
                </td>
                <th>
                    <p class="tableItem">
                        OFFSPRING AFFECTED
                    </p>
                </th>
                <td>
                    {offaffected}
                </td>
            </tr>
            {additionalcontent}
        </table>
    </div>
    <div id="endingNote">
        <p>Please reply, as soon as possible, by email at: </p>
        <a href="mailto:example@somewhere.com">example@somewhere.com</a>,
        <p>to discuss the case. If no response is recieved by </p>
        <b>{dueDate}</b>, 
        <p>action may be required, which will be documented in the case records.</p>
    </div>
    <div id="footerContainer">
        <p>
            Thank you very much in advance for your timely response.
        </p>
        <p id="sendersTitle">
            {sendersTitle}
        </p>
        <b id="sendersName">
            {sendersLastName}, {sendersFirstName}
        </b>
    </div>
    <div id="noteContainer">
        <b>Note:</b>
        <p id="note">
            This message was sent by our automated animal database system (PenPal™). 
            For database related questions please contact:
        </p> 
        <a href="mailto:support@tricorderarray.com">support@tricorderarray.com</a>
    </div>
</body>

*/