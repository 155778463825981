import triLogo from "../../images/company/Tricorderlogo.png";
import house from "../../images/icons/house.png";
import email from "../../images/icons/email.png";
import telephone from "../../images/icons/telephone.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer style={{ fontFamily: "MAGISTRAL-BOOK" }} className="min-w-screen h-full flex flex-col items-center bg-gray-900 font-magBook pt-4 relative z-40">
      <div style={{ fontFamily: "MAGISTRAL-BOLD" }} className="flex flex-col items-center w-auto lg:m-8 p-6">
        <img src={triLogo} alt="tricorder-logo" className="w-footer-logo" />
        <p className="font-magBold text-2xl text-shadow text-text-yellow">
          TRICORDER
        </p>
        <p className="font-magReg text-xs text-gray-100 tracking-wider">
          ARRAY TECHNOLOGIES LLC
        </p>
      </div>
      <div className="flex w-full items-center justify-center lg:m-12 text-gray-100 tracking-wider">
        <div className="flex lg:flex-row flex-col lg:max-w-full max-w-xs lg:h-8 items-center justify-center">
          <div className="flex flex-row mb-4 items-center">
            <img
              src={house}
              alt="house icon"
              className="lg:w-6 lg:h-6 lg:mx-3 w-4 h-4"
            />
            <div className="flex flex-col lg:flex-row lg:pt-2 lg:mr-12">
              <p>1500 1st Ave N. Unit 7&nbsp;</p>
              <p>Birmingham, AL 35203</p>
            </div>
          </div>
          <div className="flex flex-row mb-4 items-center">
            <img
              src={email}
              alt="email icon"
              className="lg:w-6 lg:h-6 lg:mx-3 w-4 h-4"
            />
            <Link className="lg:pt-2 lg:mr-12" to={'#'} onClick={(e) => {
              e.preventDefault();
              window.location.href="mailto:info@penpal.day"
            }}>info@penpal.day</Link>
          </div>
          <div className="flex flex-row mb-4 items-center">
            <img
              src={telephone}
              alt="telephone icon"
              className="lg:w-6 lg:h-6 lg:mx-3 w-4 h-4"
            />
            <p className="lg:pt-2">(205) 910-4546</p>
          </div>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col bg-gray-800 w-full h-auto py-4 lg:justify-center items-center text-gray-100 tracking-widest text-xs z-10">
        <p>
          <span>&copy;Copyright </span>Tricorder Array Technologies LLC 2023.
        </p>
        <p>All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
