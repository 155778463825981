let tm = String.fromCodePoint(0x02122);
export const announementData = [
  {
    id: 4,
    title: "Research & Innovation",
    date: "4/4/2023",
    link: "https://www.uab.edu/news/research/item/13503-two-uab-startups-receive-grants-from-innovate-alabama",
    linkTitle: "Two UAB startups receive grants from Innovate Alabama",
    body: [
      `The Alabama Innovation Corporation awarded two University of Alabama at Birmingham startups with grants during the second round of Innovate Alabama Supplemental Grant awards.`,
      `
      The Innovate Alabama Supplemental Grant Program awards funds to Alabama-based companies that have been granted Phase I or Phase II awards through the Federal Small Business Innovation Research Program or Federal Small Business Technology Transfer Program.
      `,
      `
      Eligible companies can apply to receive a match of up to 50 percent of their SBIR/STTR grants. Phase I Award recipients may receive up to $100,000, while Phase II Award recipients may receive up to $250,000.
      `,
      `
      The program awarded more than $3 million in grants during this round of funding to 19 winners, five of which were in the Birmingham area.
      `,
      `
      Of those five local winners, the two UAB startups are:
      `,
      `
      Adjuvax, LLC, which specializes in vaccine adjuvant discovery and development. The $100,000 Adjuvax received from Innovate Alabama will be used to develop a new adjuvant formulation for various vaccines against infectious diseases.
      `,
      `
      Tricorder Array Technologies received $250,000 to aid in developing the PenPal${tm}, a wireless device that collects environmental and mouse squeaks in research cages, to understand the behavior and environment influence on animal studies.
      `,
      `
      Adjuvax and Tricorder Array Technologies join seven UAB startups that received grants from the Alabama Innovation Corporation in 2022. Those startups include AeroNeph Therapeutics, AI Metrics, Endomimetics, Reliant Glycosciences, SunFire Biotechnologies, Trialtus Bioscience and TruSpin Nanomaterial Innovation.
      `
    ],
  },
  {
    id: 2,
    title: "Innovate Alabama Grant Awarded",
    date: "3/24/2023",
    link: "https://innovatealabama.org/2023/03/23/innovate-alabama-awards-more-than-3-million-in-small-business-grants/",
    linkTitle: "Innovate Alabama Article",
    body: [
      `Tricorder Array Technologies LLC is proud to announce the selection of the Innovate Alabama Supplemental Grant. It will be put to use in our commercialization efforts for our new sensor, PenPal${tm}.`,
    ],
  },
  {
    id: 3,
    title: "Improved Husbandry, Well-Being, and Welfare",
    date: "12/1/2022",
    link: "https://orip.nih.gov/about-orip/research-highlights/orip-support-small-businesses-leads-new-commercial-technology",
    linkTitle:
      "ORIP Support for Small Businesses Leads to New Commercial Technology Outcomes That Benefit Community Resources and Advance Research Discoveries",
    body: [
      `ORIP participates in two federal grant programs that provide funding to small businesses: the Small Business Innovation Research (SBIR) program and the Small Business Technology Transfer (STTR) program. The primary goal of ORIP’s small business programs is to attract innovative SBIR/STTR projects that could benefit research resources and communities associated with ORIP’s mission. The SBIR program is designed for domestic small businesses to engage in biomedical research and development (R&D) that has the potential for commercialization, and the STTR program is designed to facilitate cooperative R&D between small businesses and U.S. research institutions, with the potential for commercialization.`,
      `ORIP’s small business programs support the development and commercialization of technologies to (1) better understand, preserve, characterize, improve, and treat animal models for a wide range of human diseases and (2) support the development and implementation of technologies to benefit the welfare of research animals and to improve animal facilities that support biomedical and behavioral research. ORIP has aligned its small business programs to help develop new technologies that could benefit the research resources that the Office supports. This effort has enabled several downstream commercial outcomes at different stages of development—such as diagnostic tools, products to support new research capabilities, improvements to the welfare of research animals, and additional resources for the research community.`,
      `Improving or adapting new technologies is important for refining model characterization, promoting animal husbandry and welfare, enhancing the utilization of and procedures for research studies, and pursuing preclinical studies. (See the workshop report of the Validation of Animal Models and Tools for Biomedical Research, Session VIII. Technologies, Phenotyping, and Data Science for Animal Models for more information.)`,
      `Rodents`,
      `Many researchers use rodent species, such as mice and rats, as models for biomedical discovery. Rodents are one of the most utilized animals in basic and preclinical studies. These animals serve as useful models because they offer high genetic homology with humans, rapid reproduction capabilities, efficient and inexpensive systems for housing and husbandry, easier phenotypic characterization, and well-developed resources and tools for genetic manipulation.`,
      `
    ORIP’s small business programs are helping support new technologies for the creation, characterization, and evaluation of rodent models, which ultimately will improve the different stages of validation of such models for their use in biomedical research. The following small business concerns are at different stages in the development of technologies to improve rodents’ well-being and provide better devices for rodents’ use in preclinical research.
    `,
      `
    Model Creation and Characterization
    `,
      `
    Mirimus, Inc. (Cold Spring Harbor, NY; ORIP SBIR grant R44OD026184) is applying CRISPR-Cas9 genome editing and RNA interference (RNAi) knockdown technologies to develop a platform for rapid, cost-effective production of rat models using RNAi. These novel models will complement other models as tools for the preclinical development of safer and more effective therapeutics.`
      ,
      `PhotoSound Technologies, Inc. (Houston, TX; ORIP SBIR grant R44OD023029) is developing a photoacoustic fluorescence tomography (PAFT) imaging system. This novel technology will enable researchers to perform 3D functional and molecular imaging at high spatial resolution in rodent models to improve their phenotypic characterization. Recently, a PAFT prototype was used successfully to monitor placental function in mice. Preclinical data obtained with such a system could play a major role in the understanding of health problems that develop during pregnancy, their progression, and therapeutic response in the near future.
    `,
      `
      Improved Husbandry, Well-Being, and Welfare
    `,
      `
    Tricorder Array Technologies, LLC (Birmingham, AL; ORIP STTR/SBIR grants R41OD026185 and R44OD026185) is developing SIDECARe™—a wireless device that collects environmental data within cages—to better understand the influence of the environment on biological outcomes in animal studies. The team is using machine-learning algorithms to identify external environmental factors and trends that alter the well-being of mice, which will allow researchers to correlate those conditions with mouse behavior. SIDECARe™ is the first device that includes technology to categorize ultrasonic vocalizations in mice and to correlate them to behavior.
    `,
      `
    Wright Wold Scientific, LLC (Morgantown, WV; ORIP SBIR grant R43OD030200) is developing a remote cage-monitoring system using technologies that will digitize and automate cage monitoring for rodents. This system is using mouse behavioral metrics for virtual monitoring and inspection. This work is part of a broader effort to better understand the health status of animals used in research and ease the management of animal facilities by automating daily rodent health and cage inspection.
    `,
      `
    Improved Utilization and Procedures for Research Studies
    `,
      `
    ParaTechs Corporation (Lexington, KY; ORIP SBIR grant R44OD018231) is expanding the capabilities of its Nonsurgical Embryo Transfer (NSET™) devices from mice to rats. ParaTechs is developing a unique, commercializable device and technology for fast, simple, and painless nonsurgical embryo transfer in rats, which will reduce pain and distress for these laboratory animals (Figure 1). This technology will provide an alternative to surgical methods for assisted reproductive techniques. The mouse NSET is one of ORIP’s notable small business success stories.
    `,
      `
    Microtek, Inc. (San Diego, CA; ORIP SBIR grant R43OD028233) is developing the μIT™ Nebulizing Catheter—a new tool for use during preclinical stages in the development of respiratory drugs (i.e., inhaled therapeutics)—which will improve inhaled dosing strategies. Currently, drug delivery for pharmacological treatments of respiratory disorders—such as asthma, chronic obstructive pulmonary disease, and COVID-19—in animal models poses a particular challenge. This new tool could help move more inhaled therapeutics toward U.S. Food and Drug Administration (FDA) approval.
    `,
      `
    Zenalux Biomedical, Inc. (Durham, NC; ORIP STTR grant R41OD026688) is developing the Zenascope™, a portable, adjunct optical spectroscopy device that can quantitatively, accurately and precisely measure vascular and metabolic endpoints (e.g., oxygen saturation, total hemoglobin concentration, glucose uptake, mitochondrial membrane potential) in tumors of living animals. The Zenascope is being developed to satisfy the demand for systems-level technologies to measure tumor metabolism within an intact microenvironment. This new device would help inform pharmacological research strategies to prevent tumor resistance, recurrence, and metastasis. This technology has been used to determine tumor hypoxia in a rat fibrosarcoma model, which has provided insight on tumor control following radiation therapy.
    `,
      `
    Preclinical Studies
    `,
      `
    3P Biotechnologies, Inc. (Louisville, KY; ORIP STTR grant R41OD031942) is developing a technology that uses exosomes derived from cow’s milk as an effective targeted delivery system for Cas9-mediated genome editing. CRISPR Cas9 genome editing holds notable promise in the treatment of diseases with a genetic basis. The company is testing the delivery of a specific plasmid DNA containing all necessary components of this genome editing tool targeting a specific gene (NFκB), using bovine colostrum exosomes complexed with a polycationic matrix. As a proof of concept, exosomes will be attached to milk-derived lactoferrin to target in vitro bronchial epithelial cells, to minimize off-target effects. Results from this project will provide feasibility data for advancing this genome-editing tool delivery “platform” in a disease model.
    `,
      `
    Strykagen Corporation (Reno, NV; ORIP STTR grant R42OD030543) is performing preclinical safety/toxicity, pharmacokinetic, pharmacodynamics, and efficacy studies of an experimental small molecule, Stryka-969, in a mouse model for Duchenne muscular dystrophy (DMD). DMD is a rare and fatal genetic disease that leads to progressive muscle damage and compromised muscle function. Stryka-969 treatment improved regeneration and strength in dystrophic muscle in the mouse model. Results from this study have the potential to move Stryka-969 toward FDA’s Investigational New Drug application and into clinical trials for patients with DMD.
    `,
      `
    IsoPlexis, Inc. (Wilmington, DE; ORIP SBIR grant R44OD021445) developed mouse and human adaptive immune chips, a single-cell proteomic platform that has been validated in multiple mouse model studies. These studies ranged in topic from infectious disease (e.g., chikungunya virus, malaria) to investigating tumor-infiltrating lymphocytes in lung cancers and blood cancer cells from bone marrow. IsoPlexis’ platform has been used for testing of novel CAR T cell therapies, radiation therapies, and immunotherapies, helping to advance preclinical research. ORIP’s support was seminal to IsoPlexis’ preclinical program, which developed and brought to market a robust, automated, single-cell, multiplex proteomic analysis platform for preclinical studies that is used by academic and pharmaceutical researchers across areas of interest from cancer to infectious diseases, including COVID-19. The platform includes the chip, reader, and software, which now are commercially available. This technology is adaptable to maximize the biomarker discovery value for any mouse model.
    `,
    ],
  },
  {
    id: 1,
    title: "Phase II Small Business Innovation Research",
    date: "9/7/2022",
    link: false,
    linkTitle: false,
    body: [
      "Tricorder Array Technologies is pleased to announce the Phase II Small Business Innovation Research (SBIR). Notice of Award from the National Institute of Health (NIH) 44OD026185. The grant will be used to perfect and manufacture a Tricorder for Animal Welfare",
      "Mr. Robert Tindal of Tricorder, will lead the project with two co PI's Dr. Erik Dohm DMV and Mr. Samuel Misko of UAB. Dr Dohm the director of the Animal Resource Program at UAB will lead the animal model oversight research. Sam Misko, with the EIDT School of Engineering, a leading electronic and small device developer of IOT devices, will lead the device development.",
      "Our revolutionary system uses artificial intelligence (AI) to monitor, interpret and learn to ensure the well-being of the animal. It is expected to have a large impact to science—providing continuous environmental monitoring, in-cage animal needs assessment and automated census collection. By using AI Learning (ML) algorithms, the device can even interpret a mouse singing, giving us unprecedented feedback from the animal. This data is collected and used to make observations concerning animal health and the microenvironment of each cage in the vivarium. The system also provides a mobile interface that, together with notifications from the database, provides a seamless interface for both animal facility management and research staff to get real-time cage side detailed information about animal and cage status. The NIH review committee says the use of mouse ultrasonic vocalization (mUSV) monitoring integrated with environmental parameters at the cage level is highly innovative and potentially a high impact to health science.",
      "About Tricorder Array Technologies. The focus of Tricorder Array Technologies, LLC (Tricorder) develop tools and systems to collect and interpret sensor data, using the information to prevent and solve problems with animal care. Our first product, funded by this SBIR grant from the NIH, will apply to research animals. Research animals are high value assets to which research facilities are ethically bound and required to provide the best care possible. We are committed to non-evasive sensor data with a focus on novel approaches that improve research outcomes and care of these valued assets.",
      "Research reported in this publication was supported by the Office Of The Director, National Institutes Of Health of the National Institutes of Health under Award Number R44OD026185. The content is solely the responsibility of the authors and does not necessarily represent the official views of the National Institutes of Health.",
    ],
  },
];