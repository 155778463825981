import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./components/auth/InternalAuth";
import "./styles/index.css";

const msalInstance = new PublicClientApplication(msalConfig);

const root = createRoot(document.getElementById("root"));

root.render(
  <div>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </BrowserRouter>
  </div>
);
