
export const msalConfig = {
  auth: {
    clientId: "7480a2ef-946e-403c-9d24-436a98963255",
    authority: "https://login.microsoftonline.com/f0447dfc-843e-424d-8407-2371933e5092", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
 scopes: ["User.Read"]
};

// production uri: https://tricorder-reactapp.azurewebsites.net/login
// development uri: https://localhost:5000/login