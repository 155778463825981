const EmployeeCard = ({ employee }) => {
  return (
    <div style={{ fontFamily: "MAGISTRAL-BOOK" }} className="flex md:w-1/3 mx-0 md:mx-6 lg:mx-0 md:my-8 my-4 flex-wrap md:flex-col">
      <div className="w-full p-1 md:p-2 flex flex-col items-center">
        <div className="relative">
          <img
            src={employee.photo}
            alt="employee headshot"
            className={`w-250px h-250px block rounded-lg object-cover ${employee.name === 'Robby Tindal' ? "object-left" : "object-center"} opacity-100`}
          />
          <table className="flex flex-col font-chaletEightyReg">
            <thead>
              <tr>
                <th className="text-shadow-sm">{employee.name}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{employee.role}</td>
              </tr>
              {employee.phone && (
                <tr>
                  <td>{employee.phone}</td>
                </tr>
              )}
              <tr>
                <td>{employee.email}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCard;
