import poster11 from "../poster/poster1-1.png";
import poster12 from "../poster/poster1-2.png";
import poster13 from "../poster/poster1-3.png";
import poster4 from "../poster/poster4.png";
import poster8 from "../poster/poster11.png";
import poster5 from "../poster/poster5.png";
import poster6 from "../poster/poster6.png";
import poster7 from "../poster/poster7.png";
import poster9 from "../poster/poster9.png";
import ScrollToTopButton from "./buttons/ScrollToTopButton";
import { useEffect, useRef, useState } from "react";

const Sidecare = () => {
  const sensorTransceiver = useRef(null);
  const musv = useRef(null);
  const analytics = useRef(null);
  const team = useRef(null);
  const contents = useRef(null);
  const [heading, setHeading] = useState('');
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (heading === 'sensor-transceiver') {
      sensorTransceiver.current?.scrollIntoView({
        behavior: 'smooth'
      })
    } else if (heading === 'musv') {
      musv.current?.scrollIntoView({
        behavior: 'smooth'
      })
    } else if (heading === 'analytics') {
      analytics.current?.scrollIntoView({
        behavior: 'smooth'
      })
    } else if (heading === 'team') {
      team.current?.scrollIntoView({
        behavior: 'smooth'
      })
    };
  }, [heading]);

  return (
    <div className={`overflow-y-auto flex flex-col items-center lg:px-54 bg-gray-800 text-white md:tracking-wide px-10 text-lg`}>
      {isFixed ? (
        <div className="h-24"></div>
      ) : null}
      <div ref={contents} className={`${isFixed ? "fixed top-0 bg-slate-600 w-10/12 pb-1" : "p-2 bg-slate-600 w-10/12"} rounded-b-md flex flex-col items-center justify-center mb-3 md:mb-8 z-[100]`}>
        <p className={`${isFixed ? "" : ""} text-sunburst w-full md:text-center`}>Contents</p>
        <div className="w-full md:w-8/12 text-sm flex flex-col md:flex-row justify-between">
          <p className={`${heading === "sensor-transceiver" ? "text-sunburst" : "hover:text-sunburst"} font-chaletEighty cursor-pointer`} onClick={() => setHeading('sensor-transceiver')}>Sensor & Transceiver</p>
          <p className={`${heading === "musv" ? "text-sunburst" : "hover:text-sunburst"} font-chaletEighty cursor-pointer`} onClick={() => setHeading('musv')}>MUSV</p>
          <p className={`${heading === "analytics" ? "text-sunburst" : "hover:text-sunburst"} font-chaletEighty cursor-pointer`} onClick={() => setHeading('analytics')}>Data & Analytics</p>
          <p className={`${heading === "team" ? "text-sunburst" : "hover:text-sunburst"} font-chaletEighty cursor-pointer hover:text-sunburst`} onClick={() => setHeading('team')}>Research Team</p>
        </div>
      </div>
      <div onClick={() => setHeading('')}>
        <ScrollToTopButton />
      </div>
      <h1 className="text-sunburst text-4xl lg:text-6xl text-center mb-8 mt-4 lg:mt-0">
        PenPal&trade;
      </h1>
      <h6 className="md:text-xl md:mb-4 font-chaletNineteenSixty text-center">
        <i>
          A new way to collect data to monitor the health of research animals.
        </i>
      </h6>
      <hr
        style={{
          width: "70%",
          size: "1",
          align: "center",
          margin: "10px 0 30px 0",
        }}
      />
      <div ref={sensorTransceiver} className="relative top-[-64px] w-full"></div>
      <div className="flex items-center">
        <h5 className="lg:text-4xl md:text-3xl text-2xl text-shadow text-sunburst text-center">
          The collection device IOT Sensor and Transceiver
        </h5>
      </div>
      <div className="md:flex w-full md:justify-around md:px-12 md:py-8 justify-center">
        <img
          className="md:h-36 lg:w-3/12 lg:h-56 mb-4 md:mb-0"
          src={poster11}
          alt="IOT Sensor and Transceiver"
        />
        <img
          className="md:h-36 lg:w-3/12 lg:h-56 mb-4 md:mb-0"
          src={poster12}
          alt="IOT Sensor and Transceiver"
        />
        <img
          className="md:h-36 lg:w-3/12 lg:h-56 mb-4 md:mb-0"
          src={poster13}
          alt="IOT Sensor and Transceiver"
        />
      </div>
      <p className="font-chaletNineteenSixty text-justify md:w-10/12">
        Animal research is one of the first steps in medical discovery, and mice
        are the primary animal used. Institutions must commit significant
        resources and follow detailed animal care regulations to ensure that all
        animals are treated responsibly and humanely. However, even minor
        variations in environmental variables can cause problems with
        reproducibility and translation into human applications. Therefore
        solutions for monitoring environmental variables, animal health, and
        census are needed.
      </p>
      <p className="font-chaletNineteenSixty mt-6 text-justify md:w-10/12">
        Protecting the animal investment is the primary purpose of
        PenPal&trade;. PenPal&trade; has sensors to monitor the cage environment
        as well as issues related to animal welfare. Additional value features
        include a cage side (tap) mobile interface, cloud based data storage and
        access, and automated census. PenPal&trade; sensors report on:
      </p>
      <ul className="text-md my-4 md:text-lg lg:text-xl flex flex-col lg:flex-row lg:justify-around items-center lg:w-8/12 text-sunburst text-shadow md:my-10 text-xl">
        <li className="cursor-default">
          <b>Ultrasonic Vocalization</b>
        </li>
        <li className="cursor-default">
          <b>Temperature</b>
        </li>
        <li className="cursor-default">
          <b>Humidity</b>
        </li>
        <li className="cursor-default">
          <b>Motion</b>
        </li>
        <li className="cursor-default">
          <b>Light</b>
        </li>
      </ul>
      <p className="font-chaletNineteenSixty text-justify md:w-10/12">
        Everyday research animals encounter events important to their care. For
        example, temperature, humidity and light control are critical to normal
        metabolism. Motion detects if a cage is in use or empty. Much of mouse
        communication occurs ultrasonically, so by monitoring the ultrasonic we
        get a glimpse into mouse activity.
      </p>
      <p className="font-chaletNineteenSixty md:mt-6 md:mb-4 text-justify md:w-10/12 mt-4">
        Mouse Ultrasonic Vocalizations (MUSV), processed through Machine
        Learning (ML) and Artificial Intelligence (AI) algorithms, can identify
        mouse pups, mating, happy and content animals verses stressed animals.
        Knowing about events allows intervention that improves the ability to
        care for the animals.
      </p>
      <hr
        style={{
          width: "70%",
          size: "1",
          align: "center",
          margin: "30px 0 40px 0",
        }}
      />
      <div ref={musv} className="relative top-[-64px] w-full"></div>
      <div className="flex items-center xl:w-8/12 mb-8">
        <h5 className="lg:text-4xl md:text-3xl text-2xl text-shadow text-sunburst text-center">
          Animal Welfare Linked to Mouse Ultrasonic Vocalization (MUSV)
        </h5>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "auto",
          margin: "",
          alignItems: "center",
        }}
      >
        <div className="flex flex-col lg:flex-row lg:items-center">
          <div className="lg:basis-1/2 flex lg:justify-end justify-center">
            <p className="font-chaletNineteenSixty md:mb-4 md:max-w-lg text-justify mb-8">
              Initial MUSV information and data came from an on-line source,
              MouseTube. Our own collection of MUSV (pictured) came soon after,
              in home cage environments using stand alone ultrasonic
              microphones. In the image each colored squiggle is a sonogram
              representing a mouse syllable. Processing of the recordings used a
              series of tools scripted in Python. This quickly expanded with the
              addition of a new tool that runs in MATLAB called Deep Squeak.
              Deep Squeak provided us with a spectrogram comparison and grouping
              of different mouse vocalizations known as TSNE graphs. The image
              above depicts such a grouping.
            </p>
          </div>
          <div className="lg:basis-1/2 w-full lg:h-fit flex justify-center lg:pr-12 mb-8">
            <img
              src={poster8}
              alt="MUSV"
              className="md:w-7/12 md:my-8 md:object-center"
            />
          </div>
        </div>
      </div>
      <p className="md:mt-8 font-chaletNineteenSixty text-justify md:w-10/12">
        “Mouse found dead” is the number one issue research institutions face
        daily. Animal facility staff visually inspect animals as closely as
        possible, but monitoring thousands of nocturnal animals that naturally
        mask symptoms of illness or distress inevitably results in routinely
        finding some animals dead without previous indication or notice of a
        problem. The breakthrough of PenPalTM is the use of mouse ultrasonic
        vocalizations to monitor their health status. MUSV analysis is an
        innovative approach to directly impact health problem detection by using
        ML and AI. Then automatically alerting staff for intervention. Research
        Computing and the use of the HPC supercomputer enabled these
        breakthroughs and advancements. PenPalTM monitors the social environment
        within a cage through MUSV analysis, matching patterns to various health
        and social events. Using AI, PenPalTM can identify:
      </p>
      <ul className="md:text-lg lg:text-xl flex flex-col items-center lg:flex-row lg:justify-around lg:w-8/12 text-sunburst text-shadow md:my-10 my-4 text-xl">
        <li className="cursor-default">
          <b>Mating</b>
        </li>
        <li className="cursor-default">
          <b>Urination</b>
        </li>
        <li className="cursor-default">
          <b>Injury (fighting)</b>
        </li>
        <li className="cursor-default">
          <b>Mouse Pup Presence</b>
        </li>
        <li className="cursor-default">
          <b>Chronic Pain (dermatitis)*</b>
        </li>
      </ul>
      <div className="flex flex-col lg:flex-row lg:items-center lg:my-6">
        <div className="lg:basis-1/2 flex md:w-full md:justify-center">
          <p className="font-chaletNineteenSixty text-justify md:mx-16 lg:pb-32">
            In the TSNE graph below, AI analysis showed that the MUSV were
            significantly different between different cage conditions, cage
            change (CC), and simulated flooded cage event (FCE). They also
            clustered into discrete groupings. Most of the spectrograms in FCE
            trail downward (A). The CC spectrograms, on the other hand, tend to
            trail upward. When the spectrograms matched across collections, they
            were different frequency and strength (decibel) (B).
          </p>
        </div>
        <div className="lg:basis-1/2 w-full md:h-fit flex justify-center lg:pr-12 my-8">
          <img
            src={poster4}
            alt="TSNE graph"
            className="w-full max-w-[550px]"
          />
        </div>
      </div>
      <p className="font-chaletNineteenSixty text-justify md:my-8 md:w-10/12">
        These observations suggest that by simply analyzing the syllables,
        frequency, and strength of MUSVs, PenPalTM can provide a relatively
        clear picture of the general level of well being of the animals within
        the cage. These observations are compelling, however, continued study
        will further validate these findings and uncover future directions.
        PenPalTM development and deployment of initial prototypes will build
        that necessary data. PenPalTM will then be poised to revolutionize
        animal research and enable tremendous progress in our scientific
        discoveries by simply uncovering as yet unknown confounding experimental
        variables.
      </p>
      <hr
        loading="lazy"
        style={{
          width: "70%",
          size: "1",
          align: "center",
          margin: "30px 0 40px 0",
        }}
      />
      <div ref={analytics} className="relative top-[-64px] w-full"></div>
      <div className="flex items-center mb-8">
        <h5 className="lg:text-4xl md:text-3xl text-2xl text-shadow text-sunburst text-center">
          Data Analytics
        </h5>
      </div>
      <img
        className=""
        src={poster5}
        alt="graph a"
        style={{ width: "100%", maxWidth: "1000px", margin: "20px 0" }}
      />
      <div
        className="flex flex-col md:flex-row md:justify-around md:mb-8 w-full"
        style={{
          maxWidth: "1100px",
        }}
      >
        <img src={poster6} alt="graph b" className="md:w-1/2 w-full mb-6" />
        <img src={poster7} alt="graph c" className="md:w-1/2 w-full mb-6" />
      </div>
      <p className="font-chaletNineteenSixty text-sunburst text-justify lg:my-4 lg:w-8/12 lg:text-2xl md:text-xl md:mx-12 md:mb-4">
        PenPal&trade; data goes to the cloud where ML and AI considers the
        information with respect to the individual cage, its historical data, as
        well as cages around it. In the images above:
      </p>
      <p className="font-chaletNineteenSixty text-justify md:my-4 md:w-10/12 my-6">
        Image A depicts results from a study using a series of data loggers
        placed in selected cages spread out on a ventilated rack. The data
        clearly indicates variation in temperature, light, and humidity among
        cages on the same rack. Variation is from rack position, but also
        includes other factors such as number of mice, quantity and quality of
        enrichment, and activity of the animals (age related?) in the cage. This
        variation is currently unknown within the laboratory animal community
        and therefore not controlled for in experimental design. PenPal&trade;
        makes these variations known, so they can be controlled and mitigated.
      </p>
      <p className="font-chaletNineteenSixty text-justify md:my-4 md:w-10/12">
        Image B depicts humidity results from PenPal&trade; Minimum Viable
        Product (MVP) testing in cages with a simulated water source
        malfunction. There are two cage categories, cages on a ventilated rack
        system, and cages simply placed statically on a shelf. As expected when
        the simulation is started humidity goes up.
      </p>
      <p className="font-chaletNineteenSixty text-justify md:my-4 md:w-10/12 my-6">
        However at the same time, image C depicts temperature where the
        ventilated cage decreases temperature, but the static increases.
      </p>
      <p className="font-chaletNineteenSixty text-justify md:my-4 md:w-10/12 mb-6">
        Also note that the starting cage temperature is significantly different,
        even though the cages are in the same room with identical room
        environmental variables. This further illustrates the need for
        monitoring environmental parameters within the cage, instead of, or in
        addition to, room level monitoring. PenPal&trade; provides this
        unprecedented capability in every cage 24/7. With PenPal&trade;,
        researchers, animal husbandry and veterinary staff will have this data
        at their fingertips to help improve study robustness, reproducibility,
        and identify outliers quickly due to environmental variables. Automated
        alerts for specific circumstances, such as cage flooding, positions
        PenPal&trade; to minimize the time animals are in distress before staff
        correct the problem.
      </p>
      <div className="w-11/12 max-w-[1300px] mb-8 mt-4 md:mt-0 rounded border font-chaletNineteenSixty text-[12px] text-justify md:text-center py-4 px-10">
        <p className="md:indent-7 mb-6 md:mb-0">
          PenPal&trade; and SIDECARe&trade; are trademarks of Tricorder Array
          Technologies, LLC, ALL RIGHTS RESERVED. U.S. and INTERNATIONAL PATENTS
          PENDING, US2022/0117191, et al. ALL RIGHTS RESERVED.
        </p>
        <p className="md:indent-7">
          Used under exclusive license from the applicant, The UAB Research
          Foundation. Products and services shown are currently under
          development and not yet offered for sale to the public.
        </p>
      </div>
      <hr
        style={{
          width: "70%",
          size: "1",
          align: "center",
          margin: "20px 0 40px 0",
        }}
      />
      <div ref={team} className="relative top-[-64px] w-full"></div>
      <div className="flex items-center mb-8">
        <h5 className="lg:text-4xl md:text-3xl text-2xl text-shadow text-sunburst">
          Research Team
        </h5>
      </div>
      <img
        src={poster9}
        alt="research team"
        className="w-full mb-4 max-w-screen-lg md:w-10/12 lg:w-screen-lg"
      />
      <p className="font-chaletNineteenSixty text-justify my-4 md:w-10/12">
        Pictured (from left to right): James Hill, Madhurima Maddela PhD, Robert
        Tindal - lead PI, Emma Schmidt, Erik Dohm DVM - Co-PI, Kishan Patel, Sam
        Misko PhD - Co-PI, Joe Moore, Mike Brascome and William Monroe PhD. Not
        Pictured: Brett Byer and Howard Boyd.
      </p>
      <p className="font-chaletNineteenSixty text-justify my-4 md:w-10/12">
        Data collected by: Erik Dohm DVM, Madhurima Maddela PhD, Emma Schmidt,
        Kishan Patel, and Robert Tindal. HPC TSNE graphs by William Stonewall
        Monroe PhD. Notice of UAB Entrepreneurial Oversight Committee
        Development Plan for Tricorder Array Technologies, LLC.
      </p>
      <p className="font-chaletNineteenSixty text-justify my-4 md:w-10/12">
        ESTABLISH AND COMMUNICATE SAFE HAVENS. Safe havens are discussed with
        all those that could experience undue influence related to the
        management of the study, or analysis or reporting of the results. Safe
        havens are as follows: Undergraduate students contact: Jonathan Adams,
        PhD. Graduate students and post-doctoral trainees contact: Lisa
        Schwiebert, PhD. Employees (staff and faculty) contact: Joe Roberson.
        The Study Co-PIs maintain documentation, available on request, that
        individuals with any project contact have been so informed.
      </p>
      <p className="font-chaletNineteenSixty text-justify my-4 md:w-10/12 pb-4">
        Research reported in this publication was supported by the Office Of The
        Director, National Institutes of Health under Award Number R41OD026185
        to Tricorder Array Technologies LLC. The content is solely the
        responsibility of the authors and does not necessarily represent the
        official views of the National Institutes of Health.
      </p>
    </div>
  );
};

export default Sidecare;
