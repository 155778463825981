import headerImg from "../images/backgrounds/Screenshot 2023-09-15 at 10.46.55 AM.png"

const Articles = () => {

  return (
    <div className="min-h-[40vh] w-screen relative">
      <div className="absolute h-56 w-full overflow-hidden">
        <img src={headerImg} alt={'header background'} className="block w-full h-56 object-cover object-center" />
      </div>
      <div className="absolute top-0 w-full h-56 flex justify-center items-center">
        <div className="bg-slate-900/50 w-4/5 border border-white">
          <p className="text-white font-chaletNineteenSixty text-shadow-sm text-6xl z-30 w-full text-center">Articles</p>
        </div>
      </div>
      <div className="flex">
        <div className=""></div>
        <div className=""></div>
      </div>
    </div>
  )
};

export default Articles;