import penpalWithCage from '../images/stock/PenPal with cage 3.png';
import sickMouse from '../images/mice/TRIC PP Sick VIG.png';
import coldMouse from '../images/mice/TRIC PP Cold VIG.png';
import leisureMouse from '../images/mice/TRIC PP hot VIG.png';
import mamaMouse from '../images/mice/TRIC PP Mama VIG.png';
import romanticMouse from '../images/mice/TRIC PP Romantic VIG.png';
import stressedMouse from '../images/mice/TRIC PP Stressed VIG.png';
import wetMouse from '../images/mice/TRIC PP Wet.png';
import docMouse from "../images/mice/docMouseNoBG.png";
import bg1 from "../images/backgrounds/spencertill_a_wide_angle_shot_extremely_low_on_the_floor_lookin_54875b9b-2113-4671-be54-f5320356f66d.png";
import penpalLogo from "../images/homepage/penPalTextCrop.png";
import penText from "../images/homepage/penTextCrop.png";
import palText from "../images/homepage/palTextCrop.png";

const PrototypeComplete = () => {
  let tm = String.fromCodePoint(0x02122);

  return (
    <>
      <div className="max-h-[500px] h-full w-full">
        <div className=" flex items-center justify-center z-[1] max-h-[640px] w-full bg-center absolute overflow-hidden">
          <img alt="bg-img" src={bg1} className="relative z-10 h-[640px] w-auto md:h-full md:w-full object-cover overflow-hidden" />
        </div>
      </div>
      <div className="lg:min-h-[400px] max-h-[640px] z-[50]">
        <div className="flex flex-col flex-grow items-center bg-black/40 md:mx-20 mx-4 my-6 md:my-12 py-6 rounded-md">
          <p className="w-10/12 font-magBold text-gray-200 text-shadow-sm leading-normal lg:text-6xl md:text-5xl text-4xl relative z-20 italic">
            TOMORROW'S DISCOVERY SHOULD NEVER BE THAT YOU LOST YOUR SUBJECT ANIMAL.
          </p>
        </div>
      </div>
      <div className="z-[20] w-full flex flex-col items-center justify-center relative bg-gradient-to-br from-slate-800 to-emerald-800">
        <div className='w-10/12 text-white py-4'>
          <p className='text-4xl text-cyan-400 text-shadow-sm'>Introducting the world's first A.I.-Powered home-cage monitor.</p>
          <p className='text-2xl text-shadow-sm'>Because every researcher knows the pain of losing an animal during a critical time of discovery.</p>
        </div>
        <div className='flex flex-col lg:flex-row w-full'>
          <div className='px-6 relative lg:basis-5/12'>
            <img src={penpalWithCage} alt='penpal with cage' className='z-[1] h-auto lg:min-w-[400px] w-full object-contain' />
          </div>
          <div className='z-[2] w-full lg:basis-7/12 text-xl text-cyan-600 px-12 relative flex flex-col justify-around py-12 items-center'>
            <p className='p-4 rounded-lg bg-black/30 mb-6'>Just imagine if your animals could tell you how they are feeling at any time of the day. With Penpal{tm}, that is a reality. PenPal{tm} attaches to the exterior of the animal's cage with sensors inside the cage to sense micro environmental conditions and vocalizations the animal makes. PenPal{tm} then uses artificial intelligence to discern the mental and physical condition of the animal at any given time. The device then communicates that information directly to you day or night.</p>
            <div className='flex md:flex-row  items-center justify-between md:h-auto md:w-full w-screen md:rounded-lg bg-black/30'>
              <div className='flex-1'>
                <div className='overflow-hidden w-full h-full relative' style={{ aspectRatio: "3/5" }}>
                  <img src={leisureMouse} alt='sick mouse' className='w-auto min-w-full min-h-full transform absolute inset-0 object-cover scale-[200%] object-center' />
                </div>
              </div>
              <div className='flex-1'>
                <div className='overflow-hidden w-full h-full relative' style={{ aspectRatio: "3/5" }}>
                  <img src={mamaMouse} alt='sick mouse' className='w-auto min-w-full min-h-[116%] transform absolute inset-0 object-cover scale-[200%] object-center -translate-y-1' />
                </div>
              </div>
              <div className='flex-1'>
                <div className='overflow-hidden w-full h-full relative' style={{ aspectRatio: "3/5" }}>
                  <img src={romanticMouse} alt='sick mouse' className='w-auto min-w-full min-h-[112%] transform absolute inset-0 object-cover scale-[200%] object-center -translate-y-1' />
                </div>
              </div>
              <div className='flex-1'>
                <div className='overflow-hidden w-full h-full relative' style={{ aspectRatio: "3/5" }}>
                  <img src={stressedMouse} alt='sick mouse' className='w-auto min-w-full min-h-[104%] transform absolute inset-0 object-cover scale-[200%] object-center translate-y-1 md:translate-y-2' />
                </div>
              </div>
              <div className='flex-1'>
                <div className='overflow-hidden w-full h-full relative' style={{ aspectRatio: "3/5" }}>
                  <img src={coldMouse} alt='sick mouse' className='w-auto min-w-full min-h-[110%] transform absolute inset-0 object-cover scale-[200%] object-center translate-y-2 md:translate-y-3 md:translate-x-1' />
                </div>
              </div>
              <div className='flex-1'>
                <div className='overflow-hidden w-full h-full relative' style={{ aspectRatio: "3/5" }}>
                  <img src={wetMouse} alt='sick mouse' className='w-auto min-w-full min-h-[108%] transform absolute inset-0 object-cover scale-[200%] object-center translate-y-2 md:translate-y-3 md:translate-x-2' />
                </div>
              </div>
              <div className='flex-1'>
                <div className='overflow-hidden w-full h-full relative' style={{ aspectRatio: "3/5" }}>
                  <img src={sickMouse} alt='sick mouse' className='w-auto min-w-full min-h-[106%] transform absolute inset-0 object-cover scale-[200%] translate-y-2 md:translate-y-6 md:-translate-x-2' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='z-[2] text-2xl p-12 text-white text-shadow-sm bg-black/30'>
          <p>Tricorder Array Technologies is pleased to announce the completion of our prototyping phase and entering our development and trial stage for our revolutionary product PenPal{tm}.</p>
        </div>
      </div>
      <div className='h-[560px] md:h-[700px] lg:h-[800px] xl:h-[960px] w-full flex flex-col items-center bg-gradient-to-bl from-emerald-800 to-slate-800 pt-6 relative'>
        <div className='w-full flex justify-center my-4'>
          <div className='flex w-1/2'>
            <img src={penText} alt='penpal logo' className='max-h-[250px] w-2/3 invert' />
            <img src={palText} alt='penpal logo' className='max-h-[250px] w-1/3' />
          </div>
        </div>
        <div className='z-10 flex flex-col w-10/12 text-white md:text-xl lg:text-2xl font-magBook tracking-wider'>
          <div className='my-1 flex'>
            <p className='mr-[8px] pt-[2px]'>*</p>
            <p>Automated Detection of Most Common Preventable Animal Issues</p>
          </div>
          <div className='my-1 flex'>
            <p className='mr-[8px] pt-[2px]'>*</p>
            <p>Low Per Unit Cost</p>
          </div>
          <div className='my-1 flex'>
            <p className='mr-[8px] pt-[2px]'>*</p>
            <p>Compatible With All Cage Styles</p>
          </div>
          <div className='my-1 flex'>
            <p className='mr-[8px] pt-[2px]'>*</p>
            <p>Real-Time Notifications</p>
          </div>
          <div className='my-1 flex'>
            <p className='mr-[8px] pt-[2px]'>*</p>
            <p>24/7 Monitoring of Animal Welfare</p>
          </div>
        </div>
        <img src={docMouse} alt='mouse' className='w-8/12 h-auto relative md:-top-44 lg:-top-56 md:ml-96' />
      </div>
    </>
  )
};

export default PrototypeComplete;