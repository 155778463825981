import Robby from "../../images/headshots/Robby-PenPal-Logo.png";
import Robby2 from "../../images/headshots/Robby-labcoat.png";
import Barry from "../../images/headshots/Barry-headshot-website.png";
import Jeffrey from "../../images/headshots/Jeffrey-headshot-website.png";
import EmployeeCard from "./EmployeeCard"
import defaultImage from "../../images/headshots/blank-profile-picture.png";
import Michael from "../../images/headshots/michael-website.png";
import Andrew from "../../images/headshots/andrew-website.png";
import Andrew2 from "../../images/headshots/andrew-candid.jpg";
import Jason from "../../images/headshots/jason-web.jpg";
import Payal from "../../images/headshots/payal-web.jpg";
import Josh from "../../images/headshots/josh-web1.jpg";
import Matthew from "../../images/headshots/matthew-headshot-square.jpg";
import Matthew2 from "../../images/headshots/matt-in-chair-square.jpg";

export default function Team() {
  const employees = [
    {
      photo: Robby,
      name: "Robby Tindal",
      role: "CEO - President",
      phone: "205.910.4546",
      email: "tindal@tricorderarray.com",
    },
    {
      photo: Barry,
      name: "Barry Tucker",
      role: "Contracts & Legal Affairs",
      phone: "205.830.4440",
      email: "tucker@tricorderarray.com",
    },
    {
      photo: Jeffrey,
      name: "Jeffrey Kintner",
      role: "Software Engineer",
      email: "kintner@tricorderarray.com",
    },
    {
      photo: Michael,
      name: "Michael McDavid",
      role: "Software Engineer",
      email: "mmcdavid@tricorderarray.com",
    },
    {
      photo: Andrew,
      name: "Andrew Lewis",
      role: "Software Engineer",
      email: "lewis@tricorderarray.com",
    },
    {
      photo: Jason,
      name: "Jason Schutzbach",
      role: "DSP Engineer",
      email: "jason@tricorderarray.com",
    },
    {
      photo: Payal,
      name: "Payal Patel",
      role: "Industrial Designer & Product Developer",
      email: "payalpatel@tricorderarray.com",
    },
    {
      photo: Josh,
      name: "Joshua Patton",
      role: "Software Engineer",
      email: "patton@tricorderarray.com"
    },
    {
      photo: Matthew,
      name: "Matthew Fickling",
      role: "Intern",
      email: "matthew@tricorderarray.com"
    }
  ];

  return (
    <div style={{ fontFamily: "MAGISTRAL-BOOK" }} className="h-full w-full bg-gray-800 text-white ">
      <p className="text-sunburst text-4xl lg:text-6xl text-center mt-12">
        Meet Our Team
      </p>
      <div className="container mx-auto px-2 py-2 lg:px-32 lg:pt-12">
        <div className="flex-col items-center md:justify-center md:items-start -m-1 flex md:flex-row md:flex-wrap md:m-2">
          {employees.map((employee) => {
            return <EmployeeCard key={employee.name} employee={employee} />;
          })}
        </div>
      </div>
    </div>
  );
}
