import { useEffect, useState } from "react"
import upArrow from '../../images/icons/up-arrow.png';

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false)
    };
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      window.addEventListener('scroll', toggleVisible)
    };
  }, []);

  return (
    <button className={`fixed flex justify-center items-center bg-black z-[9999999] top-[20vh] -right-[36px] ${visible ? "opacity-80 -translate-x-16" : "translate-0 opacity-0"} w-[42px] h-[42px] rounded-full transition-all duration-300 ease-in-out`} onClick={scrollToTop}>
      <img alt="up arrow" src={upArrow} className="bg-white w-[42px] h-[42px] rounded-full" />
    </button>
  )
}

export default ScrollToTopButton;