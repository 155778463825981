import { useState, useEffect } from "react";
import bg1 from "../images/backgrounds/Screenshot 2023-09-15 at 10.41.45 AM.png"
import bg2 from "../images/backgrounds/spencertill_a_wide_angle_shot_extremely_low_on_the_floor_lookin_54875b9b-2113-4671-be54-f5320356f66d.png";
import bg3 from "../images/backgrounds/Screenshot 2023-09-15 at 10.40.03 AM.png";
import { announementData } from "../utils/announcementData";
import Announcement from "./Announcement";
import ScrollToTopButton from "./buttons/ScrollToTopButton";
import PrototypeComplete from "./prototypeCompleteAnnouncement";

const NewAnnouncements = () => {

  return (
    <div className="w-full min-h-[60vh] max-w-[1600px] flex flex-col items-center bg-gray-800">
      <ScrollToTopButton />
      {/* <div className="w-full flex flex-col items-center relative">
        <div className="w-full flex flex-col items-center h-auto relative">
          <div className="z-[100] h-full w-full bg-center absolute">
            <img alt="bg-img" src={bg1} className="relative z-10 h-full w-full object-cover overflow-hidden" />
          </div>
          <div className="z-[500] flex flex-col flex-grow items-center bg-white/40 md:mx-20 mx-4 mt-12 py-6 rounded-md">
            <p className="w-10/12 font-magBold text-slate-900 text-shadow-white leading-normal lg:text-6xl md:text-5xl text-4xl relative z-20 italic">
              A.I.-Powered Cage Monitoring
            </p>
            <p className="w-10/12 mt-8 font-magBold text-slate-900 text-shadow-white leading-normal lg:text-4xl md:text-3xl text-xl relative z-20">
              Tricorder Array Technologies is pleased to announce the completion of our prototyping phase and entering our development and trial stage for our revolutionary product PenPal&trade;
            </p>
          </div>
          <div className="z-[99999] flex justify-center mb-10">
            
          </div>
        </div>
      </div> */}
      <div className="w-full flex flex-col items-center min-h-[400px] relative">
        <PrototypeComplete />
      </div>
      <div className="w-full flex flex-col items-center min-h-[400px] relative">
        <div className="z-[1] h-full w-full bg-center absolute">
          <img alt="bg-img" src={bg3} className="relative z-10 h-full w-full object-cover overflow-hidden" />
        </div>
        <div className="z-[500] flex flex-col flex-grow items-center bg-white/40 md:mx-20 mx-4 mt-20 py-6 rounded-md">
          <p className="w-10/12 font-magBold text-slate-900 text-shadow-white leading-normal lg:text-6xl md:text-5xl text-4xl relative z-20 italic">
            Understand the influence of the environment on biological outcomes in animal studies.
          </p>
        </div>
        <div className="z-[99999] flex justify-center mb-10">
          <Announcement announcement={announementData[2]} text={'black'} />
        </div>
      </div>
      <div className="w-full flex flex-col items-center min-h-[400px] relative">
        <div className="z-[1] h-full w-full bg-center absolute">
          <img alt="bg-img" src={bg2} className="relative z-10 h-full w-full object-cover overflow-hidden" />
        </div>
        <div className="z-[500] flex flex-col flex-grow items-center bg-black/40 md:mx-20 mx-4 mt-20 py-6 rounded-md">
          <p className="w-10/12 font-magBold text-gray-200 text-center text-shadow-sm leading-normal lg:text-6xl md:text-5xl text-4xl relative z-20 italic">
            Tricorder Awarded SBIR Funding
          </p>
        </div>
        <div className="z-[99999] flex justify-center mb-10">
          <Announcement announcement={announementData[3]} text={'white'} />
        </div>
      </div>
    </div>
  )
}

export default NewAnnouncements;