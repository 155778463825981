import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const GET_FORMS_URL =
  "https://sql-database-connection-rest-api.azurewebsites.net/api/HttpTrigger";

const initialState = {};

export const fetchInfoForms = createAsyncThunk(
  "infoForms/addInfoForms",
  async () => {
    try {
      const response = await axios.get(GET_FORMS_URL);
      const [data] = response.data.records.recordsets;
      return data;
    } catch (e) {
      return e.message;
    }
  }
);

export const infoFormsSlice = createSlice({
  name: "infoForms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInfoForms.fulfilled, (state, action) => {
      return action.payload.reduce((newState, form) => (newState[form.ID] = form, newState) ,{});
    });
  },
});

export default infoFormsSlice.reducer;
