import * as react from "react";
import navLogo from "../../images/company/Tricorderlogo.png";
import { Link } from "react-router-dom";

const TopNav = () => {
  let tm = String.fromCodePoint(0x02122);
  const [openMenu, setOpenMenu] = react.useState(false);

  return (
    <nav className="bg-yellow-600 w-full z-[9999] relative">
      <div className="mx-auto max-w-screen-2xl px-2 sm:px-2 lg:px-2 flex justify-between z-20">
        <div className="relative flex h-16 items-center justify-between w-full z-20">
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start lg:ml-40 md:ml-10 z-20">
            <Link to="/" className="flex flex-shrink-0 items-center z-20">
              <img
                className="block w-auto h-14"
                src={navLogo}
                alt="Tricorder Array Logo"
              />
              <div className="flex flex-col items-center w-auto">
                <p
                  style={{ fontFamily: "MAGISTRAL-BOLD" }}
                  className="text-shadow m-0 w-auto text-3xl text-text-yellow"
                >
                  TRICORDER
                </p>
                <p
                  style={{ fontFamily: "MAGISTRAL-BOLD" }}
                  className="font-magBold m-0 w-auto text-xs text-yellow-900 tracking-wider"
                >
                  ARRAY TECHNOLOGIES LLC
                </p>
              </div>
            </Link>
          </div>
          {/* <!-- Mobile menu button--> */}
          <button
            type="button"
            onClick={() => setOpenMenu(!openMenu)}
            className="md:hidden inline-flex items-center justify-center rounded-md mr-4 text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white z-20"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            {/* <!--
            Icon when menu is closed.
            
            Menu open: "hidden", Menu closed: "block"
          --> */}
            <svg
              className="block h-6 w-6 z-20"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
            {/* <!--
            Icon when menu is open.
            
            Menu open: "block", Menu closed: "hidden"
          --> */}
            <svg
              className="hidden h-6 w-6 z-20"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="hidden md:ml-6 md:block lg:mr-40 md:mr-2 z-20">
            <div style={{ fontFamily: "HANDELGOTHICITCPRO-REGULAR" }} className="flex space-x-4 items-center h-full font-handelGothReg">
              {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
              <Link
                to="/penpal"
                className="text-text-yellow text-shadow lg:text-sm hover:text-white rounded-md px-3 py-2 text-sm font-medium self-center "
                aria-current="page"
              >
                PenPal{tm}
              </Link>

              <Link
                to="/announcements"
                className="text-text-yellow text-shadow lg:text-sm hover:text-white rounded-md px-3 py-2 text-sm font-medium self-center"
              >
                Announcements
              </Link>

              <Link
                to="/team"
                className="text-text-yellow text-shadow lg:text-sm hover:text-white rounded-md px-3 py-2 text-sm font-medium self-center"
              >
                Our Team
              </Link>

              <Link
                to="/info"
                className="text-text-yellow text-shadow lg:text-sm hover:text-white rounded-md px-3 py-2 text-sm font-medium self-center"
              >
                More Information
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile menu, show/hide based on menu state. */}
      {openMenu && (
        <div
          className="sm:hidden absolute bg-yellow-600 right-3 top-[70px] rounded-lg z-50"
          id="mobile-menu"
        >
          <div style={{ fontFamily: "HANDELGOTHICITCPRO-REGULAR" }} className="space-y-1 px-2 pt-2 pb-3">
            {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
            <Link
              to="penpal"
              className="text-white text-right block rounded-md px-3 py-2 text-base font-medium"
              aria-current="page"
            >
              PenPal{tm}
            </Link>

            <Link
              to="announcements"
              className="text-white text-right block rounded-md px-3 py-2 text-base font-medium"
            >
              Announcements
            </Link>

            <Link
              to="/team"
              className="text-white text-right block rounded-md px-3 py-2 text-base font-medium"
            >
              Our Team
            </Link>

            <Link
              to="/info"
              className="text-white text-right block rounded-md px-3 py-2 text-base font-medium"
            >
              More Information
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default TopNav;
